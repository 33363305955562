import { HandledError } from '../handledError';

export enum LoginErrorCode {
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    ACCOUNT_NOT_ACTIVATED = 'ACCOUNT_NOT_ACTIVATED'
}
export class LoginError extends HandledError<LoginErrorCode> {}

export enum RegisterErrorCode {
    EMAIL_TAKEN = 'EMAIL_TAKEN',
    ACCOUNTS_LIMIT_REACHED = 'ACCOUNTS_LIMIT_REACHED',
    CLIENT_ALREADY_EXISTS = 'CLIENT_ALREADY_EXISTS',
    INVALID_FIELD = 'INVALID_FIELD'
}
export class RegisterError extends HandledError<RegisterErrorCode> {}

export enum ActivationErrorCode {
    ALREADY_ACTIVATED = 'ALREADY_ACTIVATED',
    USER_NOT_FOUND = 'USER_NOT_FOUND'
}
export class ActivationError extends HandledError<ActivationErrorCode> {}

export enum PasswordResetErrorCode {
    EMPTY_PASSWORD = 'EMPTY_PASSWORD',
    USER_NOT_FOUND = 'USER_NOT_FOUND'
}
export class PasswordResetError extends HandledError<PasswordResetErrorCode> {}
